<template>
    <v-popup
        overlay
        closeOverlay
        v-model:visible="isVisible"
        keyPopup="signUpAgency"
        :closeable="false"
        class="!rounded-none"
    >
        <template #header class="mb-7.5 relative">
            <span
                class="i-custom-close2 w-4 h-4 text-bw-04 absolute z-5 top-3 right-3 cursor-pointer hover:text-bw-02"
                @click=";[$emit('update:visible', false), (isVisible = false)]"
            ></span>
        </template>
        <div class="w-full py-8 lg:py-16 px-0 md:px-8">
            <p class="text-2xl font-bold text-blacks-90 text-center">Xác nhận đăng ký chương trình</p>
            <form class="w-full h-full mt-8 space-y-4">
                <div class="relative">
                    <label
                        for="contact_support_email"
                        :class="errorEmail ? '!text-error-01' : ''"
                        class="text-sm text-blacks-70 font-medium"
                        >Email</label
                    >
                    <input
                        type="text"
                        name="email"
                        id="contact_support_email"
                        :class="errorEmail ? '!placeholder:text-error-01/80 border-error-01 !text-error-01' : ''"
                        class="w-full py-2.5 px-3.5 b-1 b-blacks-10 mt-1.5"
                        v-model="infoUser.email"
                        placeholder="email@company.com"
                    />
                </div>
                <div class="relative">
                    <label
                        for="contact_support_phone"
                        :class="errorPhone ? '!text-error-01' : ''"
                        class="text-sm text-blacks-70 font-medium"
                        >Số điện thoại</label
                    >
                    <input
                        type="text"
                        name="phone"
                        id="contact_support_phone"
                        :class="errorPhone ? '!placeholder:text-error-01/80 border-error-01 !text-error-01' : ''"
                        class="w-full py-2.5 px-3.5 b-1 b-blacks-10 mt-1.5"
                        v-model="infoUser.phone"
                        placeholder="(+84) 0000 00000"
                    />
                </div>
                <div class="relative">
                    <label
                        for="contact_support_phone"
                        :class="errorPhone ? '!text-error-01' : ''"
                        class="text-sm text-blacks-70 font-medium"
                        >Bạn là đại lý cấp mấy</label
                    >
                    <Selection
                        v-model:itemModel="levelAgency"
                        v-model:checkEmpty="errorAgency"
                        :data="dataProvince"
                        placeholder="Chọn tỉnh/thành phố"
                    />
                </div>
                <button
                    class="btn-arrow btn-effect-dark w-full flex items-center justify-center text-white bg-blacks-100 px-12 mt-10 lg:mt-24 h-15"
                >
                    <p>Đăng ký</p>
                </button>
            </form>
        </div>
    </v-popup>
</template>

<script lang="ts">
export default {}
</script>

<script setup lang="ts">
const infoUser = ref({
    email: '',
    phone: ''
})
const levelAgency = ref({})
const errorEmail = ref(false)
const errorPhone = ref(false)
const errorAgency = ref(false)
const checkPhone = () => {
    const vnf_regex = /((09|03|07|08|05)+([0-9]{8})\b)/g
    if (infoUser.value.phone) {
        const convertedPhoneNumber = infoUser.value.phone.replace(/^\+84/, '0')
        if (vnf_regex.test(convertedPhoneNumber)) {
            errorPhone.value = false
        } else {
            errorPhone.value = true
        }
    } else {
        errorPhone.value = true
    }
}
const checkEmail = () => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    if (emailRegex.test(infoUser.value.email)) {
        errorEmail.value = false
    } else {
        errorEmail.value = true
    }
}
const isVisible = ref(false)
</script>

<style>
.box-content-popup-signUpAgency {
    @apply rounded-none w-xl;
}
</style>
